<template lang="pug">
div.container
  div(v-if="!affiliate.name")
    loading-spinner(message="Loading affiliate data...")
  div(v-else)
    b-modal(ref="membersAddModal" title="Pre-Flight Check" header-bg-variant="info" header-text-variant="light" ok-title="Add" @ok="addMembers(newMembers)" size="lg")
      b-container(fluid)
        b-row.mb-1
          h6 This is what we got from your CSV string. All good?
        b-row
          table.table.table-bordered.table-striped.mb-5
            thead.thead-dark
              tr
                th ID
                th First Name
                th Last Name
                th Email
            tbody
              tr(v-for="member in newMembers" :key="member.idSuffix")
                td {{affiliate.memberIdPrefix}}{{affiliate.createdDate | moment("YY")}}-{{member.idSuffix}}
                td {{member.firstName}}
                td {{member.lastName}}
                td {{member.email}}

    b-row.mb-4
      h1 Edit Affiliate

    div.row
      div.col-md-4.d-none.d-md-block
        b-list-group.pb-3
          b-list-group-item(active).d-flex.justify-content-between.align-items-center Membership Details #[fa-icon(:icon="affiliate.organisationType === 'Business' ? 'city' : 'graduation-cap'")]
          b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{affiliate.name}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Starts <span>{{affiliate.createdDate | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Expires <span>{{affiliate.validUntil | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Type #[span {{ affiliate.organisationType }}]
          b-list-group-item.d-flex.justify-content-between.align-items-center Members <span>{{affiliate.members.length}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Member IDs <span>{{affiliate.memberIdPrefix}}{{affiliate.createdDate | moment("YY")}}-**</span>
          b-list-group-item.text-center(v-if="globalState.userRole >= 3")
            b-button.mb-3(variant="info" size="sm" :href="'/affiliates/new/?dupe=' + this.$route.params.id") Duplicate for Renewal
            b-button(variant="info" size="sm" :href="'/api/affiliates/' + this.$route.params.id + '/csv'") Download Members as CSV

        b-list-group.pb-3
          b-list-group-item(active) Contact Information
          b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{affiliate.contact.name}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Email <a :href="'mailto:' + affiliate.contact.email">{{affiliate.contact.email}}</a>

        b-list-group.pb-3
          b-list-group-item(active) Remove Affiliate From Database
          b-list-group-item.text-center
            p(v-if="globalState.userRole >= 4") Warning: can not be undone!
            p(v-else) Dangerous - for admins only
            b-button(variant="danger" @click="deleteAffiliate()" :disabled="globalState.userRole < 4") Delete Affiliate

      div.col-md-8
        b-form
          b-form-row
            b-form-group.col(label="Company Name *" label-for="name")
              b-input(type="text" name="name" v-model="affiliate.name" placeholder="Arrogant Games" required)
            b-form-group.col(label="Member ID Prefix *" label-for="name")
              b-input(type="text" name="prefix" v-model="affiliate.memberIdPrefix" placeholder="ARR" required)
          b-form-row
            b-form-group.col(label="Main Contact Name *" label-for="contactName")
              b-input(type="text" name="contactName" v-model="affiliate.contact.name" required)
            b-form-group.col(label="Main Contact Email *" label-for="contactEmail")
              b-input(type="email" name="contactEmail" v-model="affiliate.contact.email" required)
          b-form-row
            b-form-group.col(label="Notes" label-for="notes")
              b-form-textarea(name="notes" v-model="affiliate.notes" :rows="3" :max-rows="6" placeholder="Special arrangements or other considerations that have been discussed with them?")
          b-form-row
            b-form-group.col(label="Organisation Type *" label-for="type")
              b-form-select(name="type" v-model="affiliate.organisationType" :options="['Business', 'Academic']" required :bootstrap-styling="true")
            b-form-group.col(label="Membership Starts *" label-for="starts")
              datepicker(name="starts" v-model="affiliate.createdDate" required :bootstrap-styling="true")
            b-form-group.col(label="Membership Ends *" label-for="ends")
              datepicker(name="ends" v-model="affiliate.validUntil" required :bootstrap-styling="true")

          b-row.m-4(align-h="center")
            b-button(variant="success" size="lg" @click="updateAffiliate(affiliate)" :disabled="!isFormValid") Update Affiliate

    hr/

    b-row
      h3 Batch Add Members
    b-form
      b-form-row
        b-form-group.col(label="New Members CSV" label-for="batch")
          b-form-textarea(name="batch" v-model="newMembersString" :rows="6" placeholder="Teemu,Haila,teemu.haila@company.fi\nChristopher,Hamilton,chris@anothercompany.com\nAnd so on...")
      b-row(align-h="center").text-center
        p They will all get a welcome email with an IGDA org opt-in link
      b-row.m-1(align-h="center")
        b-button(variant="primary" size="lg" @click="confirmAddMembers(newMembersString)" :disabled="!newMembersString") Add Members

    hr/

    b-row
      h3 Edit Members
    b-row
      p(v-if="affiliate.members.length === 0") There are no members in this affiliate yet. Add some?
      table(v-else).table.table-bordered.table-striped
        thead.thead-dark
          tr
            th ID
            th First Name
            th Last Name
            th Email
            th
        tbody
          tr(v-for="member in affiliate.members" :key="member.idSuffix")
            td
              a(:href="`/card/affiliate/${member._id}`"): small {{member.fullId}}
            td: b-form-input(type="text" v-model="member.firstName")
            td: b-form-input(type="text" v-model="member.lastName")
            td: b-form-input(type="text" v-model="member.email")
            td
              b-button.m-1(variant="primary" size="sm" @click="updateMember(member)") Update
              //- b-button.m-1(variant="danger" size="sm" @click="deleteMember(member)") Delete     Doesn't work yet in backend
</template>

<script>
import globalState from '@/main.js'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data: function () {
    return {
      affiliate: {
        createdDate: new Date(),
        validUntil: new Date(),
        name: null,
        memberIdPrefix: '',
        notes: '',
        members: [],
        contact: {
          name: '',
          email: ''
        }
      },
      newMembersString: null,
      newMembers: [],
      globalState
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (
        this.affiliate.name === '' ||
        this.affiliate.memberIdPrefix === '' ||
        this.affiliate.contact.name === '' ||
        this.affiliate.contact.email === ''
      ) {
        result = false
      }
      return result
    }
  },
  mounted: function () {
    this.axios.get('/api/affiliates/' + this.$route.params.id)
      .then(res => {
        res.data.createdDate = new Date(res.data.createdDate)
        res.data.validUntil = new Date(res.data.validUntil)
        this.affiliate = res.data
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching affiliate data failed',
          text: err.response.data,
          duration: -1
        })
      })
  },
  methods: {
    confirmAddMembers: function (newMembersString) {
      this.newMembers = []
      let s = newMembersString.split('\n')
      for (let row of s) {
        console.log(row)
        row = row.split(',')
        this.newMembers.push({
          firstName: row[0],
          lastName: row[1],
          email: row[2].toLowerCase(),
          idSuffix: this.affiliate.members.length + this.newMembers.length + 1
        })
      }

      this.$refs.membersAddModal.show()
    },
    addMembers: function (newMembers) {
      this.axios.post('/api/affiliates/' + this.$route.params.id + '/members', newMembers)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Affiliate updated',
            text: res.data.name + ' is now up-to-date!'
          })

          this.$router.go()
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating affiliate failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    updateMember: function (updatedMember) {
      this.axios.put('/api/affiliates/' + this.$route.params.id + '/members/' + updatedMember.idSuffix, updatedMember)
        .then(res => {
          res.data.createdDate = new Date(res.data.createdDate)
          res.data.validUntil = new Date(res.data.validUntil)
          this.affiliate = res.data

          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Member updated'
          })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating member failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    deleteMember: function (member) {
      this.axios.delete('/api/affiliates/' + this.$route.params.id + '/members/' + member.idSuffix)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Member deleted'
          })

          this.$router.go()
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Deleting member failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    updateAffiliate: function (updatedAffiliate) {
      this.axios.put('/api/affiliates/' + this.$route.params.id, updatedAffiliate)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Affiliate updated',
            text: res.data.name + ' is now up-to-date!'
          })

          this.$router.push({ path: '/affiliates' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating affiliate failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    deleteAffiliate: function () {
      this.axios.delete('/api/affiliates/' + this.affiliate._id)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Affiliate deleted'
          })

          this.$router.push({ path: '/affiliates' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Deleting affiliate failed',
            text: err.hasOwnProperty('response') ? err.response.data : err,
            duration: -1
          })
        })
    }
  }
}
</script>
